<template>
  <div class="header_wrapper">
    <header>
      <div class="title">
        <span>File Browser - {{ $route.name }}</span>
      </div>
      <!-- <div id="search" style="padding-top: .6rem;">
        <div id="input">
          <i class="material-icons">search</i>
          <input
            v-model="searchText"
            type="text"
            :aria-label="$t('search.search')"
            :placeholder="$t('search.search')"
            @keyup.enter="openSearch"
          >
        </div>
      </div> -->
      <div>
        <template>
          <button v-show="showSaveButton" :aria-label="$t('buttons.save')" :title="$t('buttons.save')" class="action" id="save-button">
            <i class="material-icons">save</i>
          </button>

          <button @click="openMore" id="more" :aria-label="$t('buttons.more')" :title="$t('buttons.more')" class="action">
            <i class="material-icons">more_vert</i>
          </button>

          <!-- Menu that shows on listing AND mobile when there are files selected -->
          <div id="file-selection" v-if="isMobile && isListing">
            <span v-if="selectedCount > 0">{{ selectedCount }} selected</span>
            <!-- <share-button v-show="showShareButton"></share-button> -->
            <rename-button v-show="showRenameButton"></rename-button>
            <!-- <copy-button v-show="showCopyButton"></copy-button> -->
            <!-- <move-button v-show="showMoveButton"></move-button> -->
            <delete-button v-show="showDeleteButton"></delete-button>
          </div>

          <!-- This buttons are shown on a dropdown on mobile phones -->
          <div id="dropdown" :class="{ active: showMore }">
            <div v-if="!isListing || !isMobile">
              <!-- <share-button v-show="showShareButton"></share-button> -->
              <rename-button v-show="showRenameButton"></rename-button>
              <!-- <copy-button v-show="showCopyButton"></copy-button> -->
              <!-- <move-button v-show="showMoveButton"></move-button> -->
              <delete-button v-show="showDeleteButton"></delete-button>
            </div>

            <!-- <shell-button /> -->
            <!-- <switch-button v-show="isListing"></switch-button> -->
            <download-button v-show="showDownloadButton"
              @download-clicked="setFileBrowserValues"
            />
            <upload-button v-show="showUpload"></upload-button>
            <!-- <info-button v-show="isFiles"></info-button> -->

            <!-- <button v-show="isListing" @click="toggleMultipleSelection" :aria-label="$t('buttons.selectMultiple')" :title="$t('buttons.selectMultiple')" class="action" >
              <i class="material-icons">check_circle</i>
              <span>{{ $t('buttons.select') }}</span>
            </button> -->
          </div>
        </template>

        <div v-show="showOverlay" @click="resetPrompts" class="overlay"></div>
      </div>
    </header>
  </div>
</template>

<script>
import __C from '../../../../src/primitives/_constant_'

// import InfoButton from './buttons/Info'
import DeleteButton from './buttons/Delete'
import RenameButton from './buttons/Rename'
import UploadButton from './buttons/Upload'
import DownloadButton from './buttons/Download'
// import SwitchButton from './buttons/SwitchView'
// import MoveButton from './buttons/Move'
// import CopyButton from './buttons/Copy'
// import ShareButton from './buttons/Share'
// import ShellButton from './buttons/Shell'
import {mapState, mapGetters, mapMutations} from 'vuex'
import { logoURL } from '../utils/constants'
import * as api from '../api'
import buttons from '../utils/buttons'

export default {
  name: 'header-layout',
  components: {
    // InfoButton,
    DeleteButton,
    // ShareButton,
    RenameButton,
    DownloadButton,
    // CopyButton,
    UploadButton,
    // SwitchButton,
    // MoveButton,
    // ShellButton
  },
  data: function () {
    return {
      width: window.innerWidth,
      pluginData: {
        api,
        buttons,
        'store': this.$store,
        'router': this.$router
      }
    }
  },
  created () {
    window.addEventListener('resize', () => {
      this.width = window.innerWidth
    })
  },
  computed: {
    ...mapState([
      'req',
      'user',
      'loading',
      'reload',
      'multiple',
      'inputValue'
    ]),
    ...mapGetters([
      'selectedCount',
      'isFiles',
      'isEditor',
      'isListing',
      'isLogged'
    ]),
    logoURL: () => logoURL,
    isMobile () {
      return this.width <= 736
    },
    searchText: {
      get() { return this.inputValue },
      set(val) { this.setInputValue(val) }
    },
    showUpload () {
      return this.isListing
    },
    showSaveButton () {
      return this.isEditor
    },
    showDownloadButton () {
      return this.isFiles
    },
    showDeleteButton () {
      return this.isFiles && (
        this.isListing
        ? this.selectedCount !== 0
        : true
      )
    },
    showRenameButton () {
      return this.isFiles && (
        this.isListing
        ? this.selectedCount === 1
        : true
      )
    },
    showShareButton () {
      return this.isFiles && (
        this.isListing
        ? this.selectedCount === 1
        : true
      )
    },
    showMoveButton () {
      return this.isFiles && (
        this.isListing
        ? this.selectedCount > 0
        : true
      )
    },
    showCopyButton () {
      return this.isFiles && (
        this.isListing
        ? this.selectedCount > 0
        : true
      )
    },
    showMore () {
      return this.isFiles && this.$store.state.show === 'more'
    },
    showOverlay () {
      return this.showMore
    }
  },
  methods: {
    ...mapMutations([ 'setInputValue' ]),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setModalOpened'
    ]),

    openSidebar () {
      this.$store.commit('showHover', 'sidebar')
    },
    openMore () {
      this.$store.commit('showHover', 'more')
    },
    openSearch () {
      this.$store.commit('showHover', 'search')
      this.setModalOpened(true)
    },
    toggleMultipleSelection () {
      this.$store.commit('multiple', !this.multiple)
      this.resetPrompts()
    },
    resetPrompts () {
      this.$store.commit('closeHovers')
    },

    setFileBrowserValues(v) {
      this.$emit('download-clicked', v)
    }
  }
}
</script>
